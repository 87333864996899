import React from 'react';
import './App.css';
import Body from "./Components/Body/Body";
import {BrowserRouter} from "react-router-dom";

function App() {

    const showDisclaimer = () => {
        const event = new CustomEvent("showDisclaimer");
        window.dispatchEvent(event);
    };

    let footer = (
        <div className="Footer">
            <span>© {new Date().getFullYear()} Amerigrand.com. All rights reserved. | </span>
            <button onClick={showDisclaimer}>Disclaimer & Privacy Policy</button>
        </div>
    );

    return (
        <div className="App">
            <BrowserRouter>
                <div className={"top-image"}>
                </div>
                <div className={"App-Header"}>
                    <span className={"App-Login"}><a href={"https://portal.amerigrand.com/"}>Login</a></span>
                    <span>
                        Beta
                    </span>
                </div>
                <div className={"App-Inner"}>
                    <Body/>
                </div>
		    <div className={"App-Footer"}>
			{footer}
		    </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
