import React, { useState, useEffect } from 'react';

import "./Body.css";
import "../Navigation/Navigation.css";

import {useLocation} from "react-router-dom";
import {HashLink as Link} from 'react-router-hash-link';
import Popup from 'reactjs-popup';


function Body() {
    const location = useLocation();
    const [popupVisible, setPopupVisible] = useState(false);

    let page = location.pathname;

    let navigation = <div className="Navigation">
        <div className={"Navigation-Inner"}>
            <Link to={"/#commercial-fishing"} className={page === "commercial-fishing" || page === "" ? "active" : ""}>COMMERCIAL
                FISHING</Link>
            <span className={"menu-separator"}></span>
            <Link to={"/#trading"} className={page === "trading" ? "active" : ""}>TRADING</Link>
            <span className={"menu-separator"}></span>
            <Link to={"/#licensing"} className={page === "licensing" ? "active" : ""}>LICENSING</Link>
            <span className={"menu-separator"}></span>
            <Link to={"/#vessels"} className={page === "vessels" ? "active" : ""}>VESSELS</Link>
            <span className={"menu-separator"}></span>
            <Link to={"/#about-us"} className={page === "about-us" ? "active" : ""}>ABOUT US</Link>
            <span className={"menu-separator"}></span>
            <Link to={"/#contact-us"} className={page === "contact-us" ? "active" : ""}>CONTACT US</Link>
        </div>
    </div>;

    let section = <div>
            <div id={"commercial-fishing"}>
                <h1>COMMERCIAL FISHING</h1>
                <p>
                    Large scale fishing intended to supply food for a large population in any geographic area is a
                    mission that requires a well-tuned operational strategy, discipline, sufficient investment, skilled
                    sailors, and a corporate team that creates <span className={"bold"}>harmony between operation in the high seas and market demand.</span>
                </p>
                <p>
                    Commercial Fishing in the high seas of major bodies of water such as Indian Ocean, Atlantic, and
                    Pacific Ocean is the most viable approach for gathering the necessary food supply for many millions
                    of people that consume seafood products everyday.
                </p>
                <h2>The Catch</h2>
                Migartory species along with regional Salmon, Red Snapper, & Tilapia are popular in
                many markets, however forecasts predict there will be demand for introduction of other types of fish species and acquatic products.
            
            
                

            </div>
            <span className={"section-separator"}></span>
            <div id={"trading"}>
                <h1>TRADING</h1>
                <h2>Import & Export</h2>
                Building a state of the art platform for aggregating fish market data through attracting an extensive group of suppliers and their client network, the Amerigrand platform may serve as a driving force for harmonizing market
                prices and delivering top quality seafood products by efficient low cost logistic solutions. 

                <h2>Amerigrand Platform</h2>
                Implementing technology in our operation is part of our core strategy. Amerigrand platform seeks to
                bring market and inventory information to clients. Clients will be able to track orders and shipments
                via the platform and submit pertinent documents through its gateways. Should many suppliers cooperate we
                will feed <span className={"bold"}>live data reports</span> obtained via satellite coordinated
                gravimetric measurements. The platform’s development and launch have been postponed indefinitely.
            


            </div>
            <span className={"section-separator"}></span>
            <div id={"licensing"}>
                <h1>LICENSING</h1>
                We are able to facilitate various licences in different regions.
                
        
               
            </div>
            <span className={"section-separator"}></span>
            <div id={"vessels"}>
                <h1>VESSELS</h1>
             Owners of modern commercial fishing vessels are invited for collaboration. 
             
        


             <h2>Sustainability and Technology Integration</h2>

Modernizing fishing vessels increasingly incorporates sustainable fishing practices and technologies to minimize environmental impact and bycatch. This includes the use of selective gear types, real-time data for decision-making to avoid overfished areas, and technologies to reduce fuel consumption and greenhouse gas emissions.

Environmental Sustainability is a focal point in vessel partnerships. 
        
        
              

            </div>
            <span className={"section-separator"}></span>
            <div id={"about-us"}>
                <h1>ABOUT US</h1>
                Amerigrand, a Virginia company, has intent and purpose to engage in collaborative commercial fishing with a pair trawler strategy. Our interest in Asian fish market is based on extensive research
                and findings with respect to strong demand, and expected growth in Asian markets. Amerigrand's
                management have a past in trade and investment and recognize the timing of this opportunity.

                <h2>Human Resources</h2>
                For work inquiries, questions, or reply to any of our advertisement please email us your
                information and attach your resume.<br/>
               
            </div>
            <span className={"section-separator"}></span>
            <div id={"contact-us"}>
                <h1>CONTACT US</h1>
                Email:  inquiries@amerigrand.com<br/>
                Washington, DC<br/>
                +1 202 239 2787<br/>
            </div>
        </div>
    ;
    useEffect(() => {
        const onShowDisclaimer = () => {
            setPopupVisible(true);
        };

        window.addEventListener("showDisclaimer", onShowDisclaimer);

        return () => {
            window.removeEventListener("showDisclaimer", onShowDisclaimer);
        };
    }, []);

    return (
        <React.Fragment>
            <div>
                {navigation}
            </div>
            <div className={"App-Content-Container"}>
                <div className="Body">
                    <div className={"Body-Inner"}>
                        {section}
                    </div>
                </div>
            </div>
{popupVisible && (
  <Popup
    open
    closeOnDocumentClick
    onClose={() => setPopupVisible(false)}
    contentStyle={{ width: "90%", maxWidth: "80vw", maxHeight: "80vh", overflow: "scroll", padding: "20px", borderRadius: "5px", border: "1px solid gray", backgroundColor: "white" }}
  >
    <div>
      <h3 tabIndex={0}>Disclaimer</h3>
<p>The information and content provided on Amerigand.com ("Website") are for general informational purposes only. The Website, its owner(s), author(s), and contributors make no representations, warranties, or guarantees, express or implied, relating to the accuracy, reliability, completeness, timeliness, or suitability of the information and materials found or offered on this Website for any particular purpose.</p >
                       <p>By accessing and using this Website, you acknowledge and agree that your use of the Website is at your own risk, and that the Website, its owner(s), author(s), and contributors shall not be held liable for any errors, omissions, or inaccuracies in the content or for any actions taken in reliance thereon.</p >
                       <p>This Website may contain links to third-party websites or resources that are not owned or controlled by Amerigrand.com. These links are provided for your convenience only, and we do not endorse, approve, or assume responsibility for the content, privacy policies, or practices of any third-party websites or resources. You acknowledge and agree that Amerigrand.com shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or resources.</p >
                       <p>The content on this Website is subject to change without notice. We reserve the right to update, modify, or remove any content at any time, for any reason, without prior notice. Your continued use of the Website following any changes signifies your acceptance of those changes.</p >
<p>The content of this Website is provided "as is" and "as available" basis, without warranty of any kind, either express or implied, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. In no event shall Amerigrand.com, its owner(s), author(s), or contributors be liable for any direct, indirect, incidental, punitive, special, or consequential damages arising out of or in connection with the use of or inability to use this Website or any of its content.</p >
                       <p>This disclaimer is governed by and construed in accordance with the laws of United States of America. Any disputes arising out of or in connection with this disclaimer shall be subject to the exclusive jurisdiction of the courts of United States of America.</p >
      <button onClick={() => setPopupVisible(false)}>Close</button>
    </div>
  </Popup>
)}



        </React.Fragment>
    );
}

export default Body;
